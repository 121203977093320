.border-hr { 
  border-top: 2px dashed rgb(229 231 235);;
}


.btn {
  color: #e5dc15;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  margin: 1rem;
  padding: 0.5rem;
  border: 2px solid #e5dc15;
  border-radius: 0.5rem;
  position: relative;
  top: 0;
  transition: all 300ms ease-in-out;
}

.btn:hover {
  background: #e5dc15;
  color: #0e79b2;
}

/* .btn.b-2:active {
  top: 0.6em;
  box-shadow: 0 0.2em rgba(0, 0, 0, 0.4);
}
 */

 .push-able-header {
  background: #4F7097;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  margin: 0.5rem;
  
}



.push-able-header-active {
  background: #93A7D1;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  margin: 0.5rem;
  
}


.front-header {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #93A7D1;
  color: white;
  transform: translateY(-6px);
  will-change: transform;
  transition: transform 250ms;
}

.front-header-active {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #4F7097;
  color: white;
  transform: translateY(-6px);
  will-change: transform;
  transition: transform 250ms;
}




.push-able {
  background: #45969B;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  margin: 0.5rem;
  
}



.push-able-active {
  background: #50B6BB;
  border-radius: 12px;
  border: none;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  margin: 0.5rem;
  
}


.front {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #50B6BB;
  color: white;
  transform: translateY(-6px);
  will-change: transform;
  transition: transform 250ms;
}

.front-active {
  display: block;
  padding: 12px 42px;
  border-radius: 12px;
  font-size: 1.25rem;
  background: #45969B;
  color: white;
  transform: translateY(-6px);
  will-change: transform;
  transition: transform 250ms;
}





/* .push-able:active .front {
  transform: translateY(-2px);
}

.push-able:hover {
  background: #DE4463;
}

.front:hover {
  background:#821752;
} */

